import React from 'react';
import logoNegro from "../components/logonegro.jpeg";
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import '../index.css'

const Footer = () => {
  return (
    <footer className="page-footer black darken-3" style={{
      marginTop: 'auto',  // Pushes the footer to the bottom of the container
      padding: '10px 0',  // Adjusts the padding to make the footer smaller
    }}>
      <div className="container">
        <div className="row" style={{ alignItems: 'center' }}>
          <div className="col l6 s12">
            {/* Insert logo image here */}
            <img src={logoNegro} alt="Company Logo" style={{ height: '120px', width: 'auto' }} />
          </div>
          <div className="col l4 offset-l2 s12">
            <ul>
              <li><Link className="grey-text text-lighten-4" to="/dashboard">Inicio</Link></li>
              <li><Link className="grey-text text-lighten-5" to="/cale">Calendario</Link></li>
              <li><Link className="grey-text text-lighten-3" to="/inci">Incidencias</Link></li>
              <li><Link className="grey-text text-lighten-3" to="/emps">Empleados</Link></li>
            </ul>

          </div>
          <div className="grey-text text-lighten-4 right">
          © {new Date().getFullYear()} Licco Esd
             </div>
        </div>
      </div>

    </footer>
  );
};

export default Footer;
